.container {
  display: block;
}

.body {
  overflow: hidden;
}

.wrapper {
  top: 0;
}

.menu {
  padding-top: 60px;
  box-shadow: 0 0 15px black;
}

.menu.blue {
  background-color: rgb(22, 27, 46);
}

.menu.white {
  background-color: rgb(255, 255, 255);
}

.burger {
  position: fixed;
  top: 30px;
  right: 27px;
  width: 36px;
  height: 30px;
  transform: translateY(-50%);
}

.burger_bg {
  background-color: white;
}

.close {
  position: fixed;
  transform: translateY(-50%) !important;
  top: 45px !important;
  right: 27px !important;
  width: 36px !important;
  height: 36px !important;
}

.cross {
  position: relative;
  width: 100%;
  height: 100%;
}

.cross::before,
.cross::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
}

.cross.blue::before,
.cross.blue::after {
  background-color: white;
}

.cross.white::before,
.cross.white::after {
  background-color: rgb(22, 27, 46);
}

.cross::before {
  transform: rotate(45deg);
  top: 17px;
}

.cross::after {
  transform: rotate(-45deg);
  bottom: 17px;
}

.overlay {
  top: 0;
  left: 0;
}

@media only screen and (max-width: 400px) {
  .burger {
    width: 30px;
    height: 24px;
  }

  .close {
    width: 30px !important;
    height: 30px !important;
  }

  .cross::before {
    top: 14px;
  }

  .cross::after {
    bottom: 14px;
  }
}

@media only screen and (max-width: 300px) {
  .menu {
    margin-left: 18px;
  }
}
